<template>
  <AppPage
    :show-menu="false"
    class="centered"
    :show-footer="false"
  >
    <div
      v-if="sectionToShow === 'signup'"
      class="section"
    >
      <h2>Sign up for Free</h2>
      <form>
        <Button
          v-if="false"
          class="white"
          size="small"
          @click="withGoogle"
        >
          <IconSvg name="google" />
          Sign up with Google
        </Button>
        <p v-if="false">
          <small>Or use your email</small>
        </p>
        <input
          ref="inputName"
          v-model="name"
          type="name"
          placeholder="Your Name"
          :required="true"
        >
        <input
          ref="inputEmail"
          v-model="email"
          type="email"
          placeholder="Email"
          :required="true"
        >
        <input
          ref="inputKey"
          v-model="password"
          type="password"
          placeholder="Choose a password"
          :required="true"
        >
        <p
          v-if="error"
          class="error"
        >
          {{ error }}
        </p>
        <Button
          class="special uppercase"
          size="small"
          label="Continue"
          :has-chevron="true"
          :disabled="!email || !password"
          @click="signup"
        />
      </form>
      <p>
        <a @click="sectionToShow='signin'">Already have an account? Sign in instead.</a>
      </p>
    </div>
    <div
      v-if="sectionToShow === 'signin'"
      class="section"
    >
      <h2>Sign In</h2>
      <form>
        <Button
          v-if="false"
          class="white"
          size="small"
          @click="withGoogle"
        >
          <IconSvg name="google" />
          Sign in with Google
        </Button>
        <p v-if="false">
          <small>Or use your email</small>
        </p>
        <input
          ref="inputEmail"
          v-model="email"
          type="email"
          placeholder="Email"
          :required="true"
        >
        <input
          ref="inputKey"
          v-model="password"
          type="password"
          placeholder="Password"
          :required="true"
        >
        <p
          v-if="error"
          class="error"
        >
          {{ error }}
        </p>
        <Button
          class="special uppercase"
          label="Sign in"
          :disabled="!email || !password"
          @click="signin"
        />
      </form>
      <p><a @click="requestOneTimeSignInLink">Email me a sign in link</a></p>
      <p><a @click="sectionToShow='signup'">No account yet? Create one.</a></p>
    </div>
    <div
      v-if="sectionToShow === 'link-sent'"
      class="section"
    >
      <h2>Link emailed</h2>
      <p>
        If your email is in our system you will receive an email shortly.
      </p>
    </div>
    <div
      v-if="sectionToShow === 'totp-signin'"
      class="section"
    >
      <h2>Signing in...</h2>
      <p v-if="!error">
        Wait one moment...
      </p>
      <p
        v-else
        class="error"
      >
        {{ error }}<br><br>
        <a @click="sectionToShow='signin'; error=null">Request a new link</a>
      </p>
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import Button from "@/components/atoms/Button.vue";
import AppPage from "@/components/organisms/AppPage.vue";
import IconSvg from "@/components/atoms/IconSvg.vue";
import pageHeadMixin from "@/components/mixins/pageHeadMixin";

export default Vue.extend({
  components: {
    IconSvg,
    AppPage,
    Button
  },
  mixins: [pageHeadMixin],
  data(){
    return {
      name: '',
      email: '',
      password: '',
      key: '',
      error: '',
      success: '',
      sectionToShow: 'signup'
    }
  },
  computed: {
    isAuthenticated(){
      return this.$store.getters['user/isAuthenticated'];
    },
    head(){
      if(this.sectionToShow == 'signup'){
        return {
          title: 'Sign Up for StoryFolder | Convert Videos to Storyboards',
          description: 'Sign up for StoryFolder to start converting videos into detailed storyboards, slides, and production notes. Start for free today!',
          robots: 'noindex,follow',
        }
      }else if(this.sectionToShow == 'signin') {
        return {
          title: 'Sign In to StoryFolder | Convert Videos to Storyboards',
          description: 'Sign in to StoryFolder to start converting videos into detailed storyboards, slides, and production notes.',
          robots: 'noindex,follow',
        }
      }
      return {};
    }
  },
  watch: {
    isAuthenticated: {
      handler: async function(isAuthenticated){
        if(isAuthenticated){
          console.log('subscriptions loading');
          await this.$store.dispatch('subscriptions/load');
          console.log('subscriptions loaded');
          if(this.$route.query.redirect){
            this.$router.push(this.$route.query.redirect);
          }else{
            this.$router.push({name: 'onboard'});
          }
        }
      },
      immediate: true
    },
    sectionToShow(){
      // Reset the error if they switch between screens
      this.error = null;
    }
  },
  mounted(){
    // This is for one-time login links
    // Automatically log in using the token in the URL
    if(this.$route.name === 'totp-signin'){
      this.sectionToShow = 'totp-signin';
      this.signInWithTemporaryOneTimePassword();
    }
    if(this.$route.name === 'signin'){
      this.sectionToShow = 'signin';
    }
  },
  methods: {
    signup(){
      this.$store.dispatch('user/signup', {
        email: this.email,
        password: this.password,
        name: this.name
      }).catch(e => {
        this.error = e.message
      });
    },
    signin(){
      this.$store.dispatch('user/signin', {
        email: this.email,
        password: this.password
      }).catch(e => {
        this.error = e.message
      });
    },
    requestKey(){
      this.$store
          .dispatch('user/requestKey', {
            email: this.email
          })
          .then(e => {
            this.success = e;
            this.email = '';
          })
          .catch(e => {
            this.error = e.message;
          });
    },
    withGoogle(){
      let url = `/api/users/auth/google`;
      if(this.$route.query.redirect){
        url += `?redirect=${this.$route.query.redirect}`;
      }
      window.location.href = url;
    },
    requestOneTimeSignInLink(){
      this.$store.dispatch('user/requestSignInLink', this.email)
          .then(() => {
            this.sectionToShow = 'link-sent';
          })
          .catch(e => {
            this.error = e.message
          });
    },
    signInWithTemporaryOneTimePassword(){
      this.$store
          .dispatch('user/signInWithTOTP', this.$route.params.token)
          .then(data => {
            console.log({data});
          })
          .catch(e => {
            console.log(e);
            this.error = e;
          });
    }
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';
.centered{
  text-align: center;
  font-size: 110%;
}
.section {
  background: black;
  padding: 2em 2em;
  border-radius: 1em;
  width: 400px;
  max-width: 90vw !important;
  box-sizing: border-box;
}
/deep/ .page-content{
  margin: 0 auto;
}
h2{
  margin-top: 0.5em;
}

p{
  margin: 0 0 1em 0;
  a{
    font-size: 80%;
    color: @purpleLight;
    &:hover{
      color: @grayLight;
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}
form{
  margin:0 auto 1em auto;
  input, button{
    padding: 0.9rem 1.5rem;
    font: inherit;
    box-sizing: border-box;
    clear: both;
    width: 100%;
    border-radius: 6px;
    border: none;
    text-align: center;
    outline: none;
  }
  input{
    margin-bottom: 1em;
    background: @grayLightMedium;
    color: @black;
    &:focus{
      background: @grayLight;
    }
  }

  button{
    font-weight: 600;
  }
}

p.error {
  color: #fc7a7a;
  font-size: 90%;
}
p.green {
  color: #b3fc96;
}


</style>
